<template>

  <div class="running" v-if="giveaways_running_data">
    <GiveawayRowRunning 
      v-for="(data, item_key) in giveaways_running_data.items_giveaway" 
      :key="item_key" 
      :data="data">
      </GiveawayRowRunning>
  </div>
 
 
</template>

<script setup>


const { t } = useI18n()
const start = ref(0)
const limit = ref(20)
const is_ended = ref(false)
const {$api} = useNuxtApp()



const loadData = async function() {     
  return await $api.getGiveaways(
    start.value,
    limit.value,
    'running',
  )  
}



const { pending: is_loading,  data: giveaways_running_data } = await useLazyAsyncData('giveaways_running_data', loadData)



// watchArray([start], async ([new_start], added, removed) => {
//   console.log('watcher');
//   is_loading.value = true;
//   var dt = await loadData();

//   if(new_start == 0){
//     giveaways_data.value.items_giveaway = [];
//     is_ended.value = false;
//   }
    
//   if(dt.length == 0)
//     is_ended.value = true;  

//   giveaways_data.value.items_giveaway = [...giveaways_data.value.items_giveaway, ...dt.items_giveaway]
//   is_loading.value = false;
// }, {deep: true})



// useIntersectionObserver(
//   el_infinity_scroll,
//   ([{isIntersecting}]) => {
//     if(isIntersecting){
//       console.log('infinity scroll event');        
//       if(!is_loading.value && !is_ended.value)
//         start.value = start.value + limit.value;
//     }
//   },
//   { distance: 10 }
// )


// useHead(useNuxtApp().$head.getGiveaways());



</script>

<style scoped>
 

.running{
  display: flex;
  flex-wrap: wrap;
  gap: 0 1rem;

  margin-left: -10px;
  width: calc(100% + 20px);
}

@container pb (max-width: 600px) {
  .running{
    flex-direction: column;
  }
}

</style>
