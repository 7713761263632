<template>
  <div class="pb ">
  
    <!-- <GiveawayHow/> -->

    <!-- <br> -->

    <div class="header_row"> 
      <h1>
        {{ $t('giveaways_view_title') }}      
      </h1>  
    </div>
 


    <GiveawayMainRunning/>
   
  
    <br>


    <div class="header_row">  
      <h2>
        {{ $t('giveaways_completed_title') }}
      </h2>
    </div>



    <GiveawayMainCompleted/>

<!-- 
    <div class="completed" v-if="giveaways_data">

    <GiveawayRow 
      v-for="(data, item_key) in giveaways_data.items_giveaway" 
      :key="item_key" 
      :data="data">
      </GiveawayRow>
      
    </div>
  -->
 

  </div>
</template>


<script setup>

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'


const { t } = useI18n()
const start = ref(0)
const limit = ref(20)
const is_ended = ref(false)
const more = ref([])
const {$api, $ga} = useNuxtApp()
// const el_infinity_scroll = ref(null)
 

 



// const loadData = async function() {     
//   const response = await $api.getGiveaways(
//     start.value,
//     limit.value,
//     'completed'
//   )  
//   return response;  
// }



// const { pending: is_loading,  data: giveaways_data } = await useLazyAsyncData('giveaways_data', async () => await loadData())


// watchArray([start], async ([new_start], added, removed) => {
//   console.log('watcher');
//   is_loading.value = true;
//   var dt = await loadData();

//   if(new_start == 0){
//     giveaways_data.value.items_giveaway = [];
//     is_ended.value = false;
//   }
    
//   if(dt.length == 0)
//     is_ended.value = true;  

//   giveaways_data.value.items_giveaway = [...giveaways_data.value.items_giveaway, ...dt.items_giveaway]
//   is_loading.value = false;
// }, {deep: true})



// useIntersectionObserver(
//   el_infinity_scroll,
//   ([{isIntersecting}]) => {
//     if(isIntersecting){
//       console.log('infinity scroll event');        
//       if(!is_loading.value && !is_ended.value)
//         start.value = start.value + limit.value;
//     }
//   },
//   { distance: 10 }
// )


useHead(useNuxtApp().$head.getGiveaways());




</script>









<style scoped>

.completed{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}


@container pb (max-width: 600px) {
  .running{
    flex-direction: column;
  }
}


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.header_row{
  margin-bottom: 1rem;
}

.mansory{
  column-gap: 3%;  
  column-count: 2;
}
.mansory > *{
  width: 100%;
  display: inline-block;
}
</style>
